import * as React from 'react';
import { connect } from 'react-redux';
import isEqual from 'react-fast-compare';

import { updeepUi } from '@archinsurance-viki/property-jslib/src/actions/CommonActions';
import { DispatchType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';

import AddResponseApp from '../apps/AddResponseApp';
import { Types } from '../../../../ts-types/icubed-types';
import { getSubmissionDataForUiState, takeAction } from '../../../../actions/SubmissionActions';
import { SubmissionType } from '../../../../ts-types/DataTypes';
import { getTemplate_server, putTemplate_server } from '../../../../actions/EmailActions';
import { VoidFn, PromiseDispatchType } from '../../../../ts-types/DataTypes';
import { ACTIONS_MAPPING, EMPTY_RESPONSE_OBJECT, BLOCK_ACTION, BLOCK_ACTION_UW, EMPTY_SUBMISSION_OBJECT, DEFAULT_SID } from '../../../../constants/Constants';
import DuplicatesPanel from '@archinsurance-viki/property-jslib/src/components/panels/DuplicatesPanel';
import { useGetDuplicatesQuery } from '../../../../services/apiSlice';

type ownPropTypes = {
    onClose: (close: boolean) => void;
    currentSubmission: SubmissionType;
    modalData: {
        action: string;
        replyToEmail: string;
        currentSubmissionId: number;
    };
};

type propTypes = {
    onClearFormData: VoidFn;
    onGetBrokerTemplate: VoidFn;
    onGetDuplicateSubmission: (sid: number) => void;
    onGetUnderwriterTemplate: VoidFn;
    onPutBrokerTemplate: (body: string) => void;
    onPutUnderwriterTemplate: (body: string) => void;
    onResetTemplate: VoidFn;
    onSearchInbox: (searchTerm: string) => void;
    underwriterSubmissionRESPONSE: Record<string, any>;
    brokerSubmissionRESPONSE: Record<string, any>;
    duplicateSubmission: Record<string, any>;
    onSendResponse: (params) => PromiseDispatchType;
    uiState: {
        RESPONSE: Record<string, any>;
    };
    isLoading: boolean;
    onTakeAction: (
        brokerEmailParams: Record<string, any>,
        brokerCcEmails: string[],
        underwriterEmailParams: Record<string, any>,
        underwriterCcEmails: string[],
        emailRequestedUnderwriter: boolean,
        emailOriginatingUnderwriter: boolean
    ) => PromiseDispatchType;
} & ownPropTypes;

type reducerStateTypes = {
    uiState: Record<string, any>;
} & Types.RootState;

const mapStateToProps = (state: reducerStateTypes, ownProps: ownPropTypes) => {
    const { uiState } = state;
    const props: Record<string, any> = { uiState };
    if (uiState) {
        if (uiState.RESPONSE) {
            if (uiState.RESPONSE.action === BLOCK_ACTION) {
                props.brokerSubmissionRESPONSE = uiState.RESPONSE;
            } else if (uiState.RESPONSE.action === BLOCK_ACTION_UW) {
                props.underwriterSubmissionRESPONSE = uiState.RESPONSE;
            }
        }
        if (uiState.SUBMISSION && uiState.SUBMISSION.id) {
            props.duplicateSubmission = {
                archSid: uiState.SUBMISSION.arch_clearance_api_submission_id || DEFAULT_SID,
                vikiSid: uiState.SUBMISSION.external_sid || DEFAULT_SID,
                vmacSid: uiState.SUBMISSION.id,
            };
        }
        props.isLoading = uiState.apiCallsInProgress && Object.keys(uiState.apiCallsInProgress).length > 0;
    }
    return props;
};

const mapDispatchToProps = (dispatch: DispatchType, ownProps: ownPropTypes) => {
    const { currentSubmissionId } = ownProps.modalData;
    const onGetBrokerTemplate = (params = {}) => dispatch(getTemplate_server({ submissionId: currentSubmissionId, action: BLOCK_ACTION, ...params }));
    const onGetUnderwriterTemplate = (params = {}) => dispatch(getTemplate_server({ submissionId: currentSubmissionId, action: BLOCK_ACTION_UW, ...params }));
    return {
        onGetBrokerTemplate,
        onGetUnderwriterTemplate,
        onPutBrokerTemplate: body => {
            dispatch(putTemplate_server({ submissionId: currentSubmissionId, action: BLOCK_ACTION, body }));
        },
        onPutUnderwriterTemplate: _body => {
            // VENT-13027
            //dispatch(putTemplate_server({ submissionId: currentSubmissionId, action: BLOCK_ACTION_UW, body }));
        },
        onResetTemplate: () => {
            const params = { reset: true };
            onGetBrokerTemplate(params);
            onGetUnderwriterTemplate(params);
        },
        onGetDuplicateSubmission: (sid: number) => dispatch(getSubmissionDataForUiState(sid)),
        onTakeAction: (
            brokerEmailParams: Record<string, any>,
            brokerCcEmails: string[],
            underwriterEmailParams: Record<string, any>,
            underwriterCcEmails: string[],
            emailRequestedUnderwriter: boolean,
            emailOriginatingUnderwriter: boolean
        ) => {
            return dispatch(
                takeAction(
                    currentSubmissionId,
                    ACTIONS_MAPPING.submission_blocked,
                    brokerEmailParams,
                    brokerCcEmails,
                    true,
                    null,
                    null,
                    underwriterEmailParams,
                    underwriterCcEmails,
                    emailRequestedUnderwriter,
                    emailOriginatingUnderwriter
                )
            );
        },
        onClearFormData: () => {
            dispatch(updeepUi(EMPTY_RESPONSE_OBJECT, 'RESPONSE'));
            dispatch(updeepUi(EMPTY_SUBMISSION_OBJECT, 'SUBMISSION'));
        },
    };
};

type stateTypes = {
    brokerSubmissionRESPONSE: Record<string, any>;
    underwriterSubmissionRESPONSE: Record<string, any>;
    duplicateSubmission: Record<string, any>;
    underwriterEmailBody: string;
    initialUwEmailBody: string;
    isDuplicateSelected: boolean;
    shouldSendEmailUnderwriter: boolean;
    additionalEmails: Record<string, any>;
};

class BlockSubmission extends React.Component<propTypes, stateTypes> {
    constructor(props: propTypes) {
        super(props);
        this.state = {
            underwriterEmailBody: '',
            initialUwEmailBody: '',
            isDuplicateSelected: false,
            shouldSendEmailUnderwriter: true,
            underwriterSubmissionRESPONSE: EMPTY_RESPONSE_OBJECT,
            brokerSubmissionRESPONSE: EMPTY_RESPONSE_OBJECT,
            duplicateSubmission: Object.assign({}, EMPTY_SUBMISSION_OBJECT),
            additionalEmails: { underwriter: [], broker: [] },
        };
    }

    componentWillUnmount() {
        this.props.onClearFormData();
    }

    componentDidUpdate() {
        const stateUpdate: any = {};
        if (this.props.brokerSubmissionRESPONSE && !isEqual(this.props.brokerSubmissionRESPONSE, this.state.brokerSubmissionRESPONSE)) {
            stateUpdate.brokerSubmissionRESPONSE = this.props.brokerSubmissionRESPONSE;
        }
        // if (this.props.underwriterSubmissionRESPONSE && !isEqual(this.props.underwriterSubmissionRESPONSE, this.state.underwriterSubmissionRESPONSE)) {
        //     stateUpdate.underwriterSubmissionRESPONSE = this.props.underwriterSubmissionRESPONSE;
        // }
        if (this.props.duplicateSubmission && !isEqual(this.props.duplicateSubmission, this.state.duplicateSubmission)) {
            stateUpdate.duplicateSubmission = this.props.duplicateSubmission;
            const underwriterEmailBody = this.getUnderwriterEmailBody(this.props.duplicateSubmission);
            stateUpdate.underwriterEmailBody = underwriterEmailBody;
            stateUpdate.underwriterSubmissionRESPONSE = { ...this.props.underwriterSubmissionRESPONSE, body: underwriterEmailBody };
        } else if (this.props.underwriterSubmissionRESPONSE?.body && !this.state.initialUwEmailBody) {
            stateUpdate.initialUwEmailBody = this.props.underwriterSubmissionRESPONSE.body;
            stateUpdate.underwriterSubmissionRESPONSE = this.props.underwriterSubmissionRESPONSE;
        } else if (
            this.props.underwriterSubmissionRESPONSE &&
            !isEqual(this.props.underwriterSubmissionRESPONSE, this.state.underwriterSubmissionRESPONSE) &&
            !this.props.underwriterSubmissionRESPONSE.body.includes('PRIOR_CLEARED_SUBMISSION')
        ) {
            stateUpdate.underwriterSubmissionRESPONSE = this.props.underwriterSubmissionRESPONSE;
        }

        if (Object.keys(stateUpdate).length > 0) this.setState(stateUpdate);
    }

    getAdditionalReplyToEmails(): string[] {
        const { currentSubmission } = this.props;
        return currentSubmission.originating_underwriter_email !== currentSubmission.request_underwriter_email
            ? [currentSubmission.originating_underwriter_email]
            : [];
    }

    getUnderwriterEmailBody(duplicateSubmission: Record<string, any> = {}): string {
        const underwriterEmailBody = this.state.initialUwEmailBody;
        const { vmacSid, archSid, vikiSid } = duplicateSubmission;
        const line = `VMAC: ${vmacSid}, Arch: ${archSid}, VIKI: ${vikiSid}`;

        return underwriterEmailBody.replace('PRIOR_CLEARED_SUBMISSION', line);
    }

    handleBlockSubmission = (
        _action: string,
        _responseObj: Record<string, any>,
        _additionalEmails: string[],
        replyToEmails?: string[]
    ): PromiseDispatchType => {
        const { currentSubmission } = this.props;
        let { underwriterSubmissionRESPONSE, brokerSubmissionRESPONSE, additionalEmails } = this.state;
        let { broker: brokerAdditionalEmails, underwriter: underwriterAdditionalEmails } = additionalEmails;

        let emailOriginatingUnderwriter = false;
        let emailRequestedUnderwriterEmail = false;

        if (replyToEmails && replyToEmails.length > 0) {
            emailOriginatingUnderwriter =
                currentSubmission.originating_underwriter_email && replyToEmails.indexOf(currentSubmission.originating_underwriter_email) >= 0;
            emailRequestedUnderwriterEmail =
                currentSubmission.request_underwriter_email && replyToEmails.indexOf(currentSubmission.request_underwriter_email) >= 0;
        } else {
            emailRequestedUnderwriterEmail = true;
        }

        if (!this.state.shouldSendEmailUnderwriter) {
            underwriterAdditionalEmails = null;
            underwriterSubmissionRESPONSE = null;
            emailOriginatingUnderwriter = false;
            emailRequestedUnderwriterEmail = false;
        }
        return this.props.onTakeAction(
            brokerSubmissionRESPONSE,
            brokerAdditionalEmails,
            underwriterSubmissionRESPONSE,
            underwriterAdditionalEmails,
            emailRequestedUnderwriterEmail,
            emailOriginatingUnderwriter
        );
    };

    handleDuplicateSelection = (selectedDuplicate: string) => {
        let duplicateSubmission: Record<string, any> = Object.assign({}, EMPTY_SUBMISSION_OBJECT);
        // selected arch submission
        if (selectedDuplicate.toString().toUpperCase().startsWith('S')) {
            duplicateSubmission.archSid = selectedDuplicate;
        }
        // selected viki submission
        else if (selectedDuplicate.toString().toUpperCase().startsWith('V')) {
            duplicateSubmission.vikiSid = selectedDuplicate;
        }
        // selected new vmac submission
        else if (Number.parseInt(selectedDuplicate) !== this.state.duplicateSubmission.vmacSid) {
            this.props.onGetDuplicateSubmission(+selectedDuplicate);
            this.setState({
                underwriterSubmissionRESPONSE: { ...this.state.underwriterSubmissionRESPONSE, body: this.state.initialUwEmailBody },
                underwriterEmailBody: '',
                isDuplicateSelected: true,
            });
            return;
        } else {
            duplicateSubmission = this.props.duplicateSubmission;
        }

        const stateUpdate: any = {};
        const underwriterEmailBody = this.getUnderwriterEmailBody(duplicateSubmission);
        stateUpdate.underwriterEmailBody = underwriterEmailBody;
        stateUpdate.underwriterSubmissionRESPONSE = { ...this.state.underwriterSubmissionRESPONSE, body: underwriterEmailBody };
        stateUpdate.isDuplicateSelected = true;
        this.setState(stateUpdate);
    };

    handleAdditionalEmailsChange = (template: string): ((emails: string[]) => void) => {
        return (emails: string[]) => {
            this.setState({ additionalEmails: { ...this.state.additionalEmails, [template]: emails } });
        };
    };

    handleToggleEmailUnderwriter = () => {
        this.setState({ shouldSendEmailUnderwriter: !this.state.shouldSendEmailUnderwriter });
    };

    render() {
        const {
            uiState,
            currentSubmission,
            onGetBrokerTemplate,
            onGetUnderwriterTemplate,
            onPutBrokerTemplate,
            onPutUnderwriterTemplate,
            onResetTemplate,
            modalData,
        } = this.props;
        const brokerUIState = {
            ...uiState,
            RESPONSE: this.state.brokerSubmissionRESPONSE,
            showSecondaryEmailTemplate: this.state.shouldSendEmailUnderwriter,
        };
        const underwriterUIState = {
            ...uiState,
            RESPONSE: this.state.underwriterSubmissionRESPONSE,
        };
        const baseProps = {
            ...this.props,
            onTakeAction: this.handleBlockSubmission,
        };
        const brokerProps = {
            ...baseProps,
            uiState: brokerUIState,
            onGetTemplate: onGetBrokerTemplate,
            onPutTemplate: onPutBrokerTemplate,
            onResetTemplate,
            hideButtons: this.state.shouldSendEmailUnderwriter,
            handleAdditionalEmailsChange: this.handleAdditionalEmailsChange('broker'),
            handleToggleSecondaryEmailTemplate: this.handleToggleEmailUnderwriter,
            modalData: { ...modalData, replyToEmail: currentSubmission.reply_to_email, currentSubmissionId: currentSubmission.id },
        };
        const underwriterProps = {
            ...baseProps,
            uiState: underwriterUIState,
            onGetTemplate: onGetUnderwriterTemplate,
            onPutTemplate: onPutUnderwriterTemplate,
            onResetTemplate,
            handleAdditionalEmailsChange: this.handleAdditionalEmailsChange('underwriter'),
            sendButtonText: 'Send Emails',
            modalData: {
                ...modalData,
                currentSubmissionId: currentSubmission.id,
                replyToEmail: currentSubmission.request_underwriter_email,
                additionalReplyToEmails: this.getAdditionalReplyToEmails(),
            },
            isDisabled: (!this.state.isDuplicateSelected && currentSubmission.possible_duplicate_sids?.length > 0) || this.props.isLoading,
        };
        return (
            <>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <AddResponseApp {...brokerProps} />
                    {this.state.shouldSendEmailUnderwriter && <AddResponseApp {...underwriterProps} />}
                </div>
                <DuplicatesPanel currentSubmission={currentSubmission} goToDupe={this.handleDuplicateSelection} useGetDuplicatesQuery={useGetDuplicatesQuery} />
            </>
        );
    }
}

const connected: React.ComponentType<ownPropTypes> = connect(mapStateToProps, mapDispatchToProps)(BlockSubmission);
export default connected;
