import { UserActivityType, ColumnType, RowValidationType, TableRowDataType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import { DocumentDataType } from '@archinsurance-viki/property-jslib/src/ts-types/DataTypes';
import { EnvType, BaseUser, DispatchType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';

export type VoidFn = () => void;
export type PromiseDispatchType = Promise<DispatchType>;

export enum ArchClearanceApiStatus {
    IGNORE = 'IGNORE',
    NOT_CALLED = 'NOT_CALLED',
    PENDING_PROCESSING = 'PENDING_PROCESSING',
    PROCESSING = 'PROCESSING',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    DECLINED = 'DECLINED',
    BLOCKED = 'BLOCKED',
    RETRY = 'RETRY',
    RETRY_CLEAR = 'RETRY_CLEAR',
    ACCOUNT_CONFLICT = 'ACCOUNT_CONFLICT',
    SUBMISSION_CONFLICT = 'SUBMISSION_CONFLICT',
    PENDING_SUBMISSION_CLEAR = 'PENDING_SUBMISSION_CLEAR',
}

export enum UnderwritingPlatformType {
    VIKI = 'V',
    POLICYWRITER = 'P',
    ARCHLINK = 'A',
}

export type InboxType = {
    id: number;
    short_name: string;
    email: string;
    sub_division_id: number;
    is_active: boolean;
    is_deleted: boolean;
    imageright_enabled: boolean;
    pending_bor_enabled: boolean;
};

export type InboxStateType = {
    list: InboxType[];
    inboxId: number | null;
};

export type NoteType = {
    id: number;
    subject: string;
    message: string;
};

export type DebugInfoType = {
    last_acord_parse_on: string;
    last_acord_parse_status: string;
    last_ofac_api_call_on: string;
    last_ofac_api_call_status: string;
    last_sov_bot_on: string;
    last_sov_bot_status: string;
};

export type SubmissionType = {
    office_name: string;
    id: number;
    _validations: any;
    _clear_validations: any;
    _block_validations: any;
    _decline_validations: any;
    _current_row_viewers?: UserActivityType[];
    _is_dirty?: boolean;
    blocking_submission_ids: number[];
    incumbent_submission_ids: number[];
    documents: DocumentDataType[];
    external_sid?: string;
    props?: {
        readonly: boolean;
    };
    account_agent_id: null | number;
    office_id: null | number;
    account_agent_name?: string;
    account_agent_first_name?: string;
    account_agent_last_name?: string;
    account_agent_name_as_parsed?: string;
    assigned_to: null | number;
    asignee_research: null | string;
    status: string;
    buildings?: any[];
    has_sent_submission_cleared_email: boolean;
    has_sent_submission_blocked_email: boolean;
    has_sent_submission_declined_email: boolean;
    email_subject?: string;
    last_action: string;
    is_editable: boolean;
    insured_name?: string;
    arch_account_arch_id: string;
    arch_account_id: number;
    arch_account_fein: string;
    arch_account_city: string;
    arch_account_state: string;
    arch_account_street: string;
    arch_account_zipcode: string;
    arch_account_name: string;
    arch_account_name_as_parsed: string;
    arch_account?: any;
    reply_to_email?: string;
    submission_cleared_by?: string;
    date_cleared?: string;
    date_forwarded?: string;
    forwarded_by?: string;
    status_display: string;
    inbox_id: number;
    inbox_source: string;
    inbox_sub_division_id: number;
    is_archived: boolean;
    has_new_message: boolean;
    arch_clearance_api_submission_id?: string;
    last_user_action_failed: boolean;
    last_user_action_failed_action_type?: string;
    arch_clearance_api_status: ArchClearanceApiStatus;
    arch_clearance_api_last_call: string;
    arch_clearance_api_error?: string;
    arch_clearance_api_warning?: string;
    apiStatuses: Record<string, any>;
    underwriting_platform: UnderwritingPlatformType;
    pending_bor_status: string;
    bor_incumbent_submission_id: number;
    bor_incumbent_submission: SubmissionType;
    supported_uw_platforms: UnderwritingPlatformType[];
    assigned_to_id: number;
    assigned_research: string;
    is_submitted_too_early: boolean;
    source?: string;
    request_underwriter_email: string;
    originating_underwriter_email: string;
    possible_duplicate_sids?: number[];
    conflicts?: Record<string, any>[];
    unactioned_auto_suggested_action?: boolean;
    request_underwriter_full_name?: string;
    contact_arch_id: number;
    office_arch_id: string;
    is_renewal_linked: boolean;
};

export interface UserType extends BaseUser {
    date_joined: string;
    last_login: string;
    email: string;
    full_name: string;
    groups: any[];
    is_active: boolean;
    is_staff: boolean;
    is_superuser: boolean;
    password_hash?: string;
    user_permissions: any[];
    username: string;
    can_override_business_rules: boolean;
    can_override_arch_rules: boolean;
    can_assign_users_to_submissions: boolean;
    can_assign_submissions: boolean;
    is_underwriter_assistant: boolean;
}

export type blockPropTypes = {
    key: string;
    type?: string;
    rows?: string[];
    header: string;
    hideBlock?: (props: Record<string, any>) => boolean;
    locationContext?: string;
    noteType?: string;
    readonly: boolean;
    className?: string;
    showAddRowIcon?: boolean;
    showColHeaders?: boolean;
    filterDisplayedColumns?: (c: any) => ColumnType[];
    columns?: { key: string; className: string }[];
    enableClientMatching?: boolean;
    alwaysShowAddRowIcon?: boolean;
    headerButtons?: any;
    rowFn?: (ENV: EnvType, props?: Record<string, unknown>) => Record<string, string[]> | string[];
};

export type ApiStatusType = {
    name: string;
    type: string;
};

export type ValidationErrorType = {
    name: string;
    type: string;
};

export type ValidationType = {
    ERROR?: {
        id: number;
    };
    WARN?: {
        id: number;
    };
};

export enum BorState {
    DECISION,
    APPROVAL_EMAILS,
    DECLINE_EMAIL,
    CONFIRMATION,
    PERMISSION_ERROR,
}

export type VmacRowValidationType = {
    CLEAR?: Record<string, string | string[] | boolean>;
    BLOCK?: Record<string, string | string[] | boolean>;
    DECLINE?: Record<string, string | string[] | boolean>;
    OVERRIDE?: Record<string, string | string[] | boolean>;
    MARK_PENDING_BOR?: Record<string, string | string[] | boolean>;
    RESOLVE_PENDING_BOR?: Record<string, string | string[] | boolean>;
} & RowValidationType;

export type VmacTableRowDataType = SubmissionType & TableRowDataType;

export type VmacArchAccountType = {
    id: number;
    last_bound_submission: number | string | null;
    fein: string | null;
    created: string;
    modified: string;
    version: number;
    arch_id: string;
    viki_id: number;
    status: string;
    name_search_vector?: string;
    address_search_vector?: string;
    state_long: string;
    name: string;
    co: string;
    dba_list?: string[];
    street: string;
    street2: string;
    city: string;
    state: string;
    zipcode: string;
    country?: string;
    phone_number?: string;
    last_ofac_submit_status: string;
    last_ofac_received_on: string;
    number_of_ofac_matches: number;
    cached_ofac_api_response: {
        error: boolean;
        matches: string[];
    };
    last_ofac_submit_account_name: string;
    expiration_date: string | null;
    last_ofac_submit_by: string | null;
};
