import { AnyAction, configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import rootReducer from '../reducers';
import api from '../services/apiSlice';
import listenerMiddleware from './listeners';
import { BOTTOM_PANEL_DEFAULT_INDEX, DEFAULT_BODY } from '../constants/Constants';
import { UiStateType, defaultState as uiDefaultState } from '@archinsurance-viki/property-jslib/src/reducers/ui_state';

const DEFAULT_UI_STATE = {
    ...uiDefaultState,
    openPanels: { ...uiDefaultState.openPanels, BOTTOM_submissiondata: { open: 'half', index: BOTTOM_PANEL_DEFAULT_INDEX } },
    RESPONSE: { body: DEFAULT_BODY },
    FWD: { body: '' },
    noteObj: { subject: '', message: '' },
    debugInfo: {},
} as UiStateType;

const store = configureStore({
    reducer: rootReducer,
    preloadedState: { uiState: DEFAULT_UI_STATE },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            // TODO: Enable this
            serializableCheck: false,
            immutableCheck: false,
        })
            .prepend(listenerMiddleware.middleware)
            .concat(api.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = ThunkDispatch<RootState, any, AnyAction>; // TODO: Replace with below after figuring out why we get AnyAction
// export type AppDispatch = typeof store.dispatch;

export default store;
