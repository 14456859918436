import * as React from 'react';

import { connect } from 'react-redux';

import { DispatchType } from '@archinsurance-viki/property-jslib/src/ts-types/GlobalTypes';
import AddResponseApp from '../apps/AddResponseApp';
import { updeepUi } from '@archinsurance-viki/property-jslib/src/actions/CommonActions';
import { EMPTY_RESPONSE_OBJECT, ACTIONS_MAPPING } from '../../../../constants/Constants';
import { sendEmailResponse_server, getTemplate_server, putTemplate_server, getReplyTemplates_server } from '../../../../actions/EmailActions';
import { searchInbox_server } from '../../../../actions/InboxActions';
import { takeAction } from '../../../../actions/SubmissionActions';
import { Types } from '../../../../ts-types/icubed-types';
import { VoidFn, PromiseDispatchType } from '../../../../ts-types/DataTypes';

type ownPropTypes = {
    onClose: (close: boolean) => void;
    modalData: {
        currentSubmissionId: number;
        action: string;
        replyToEmail: string;
    };
};

type propTypes = {
    onClearFormData: VoidFn;
    onGetTemplate: VoidFn;
    onPutTemplate: (body: string) => void;
    onResetTemplate: VoidFn;
    onSendResponse: (params) => PromiseDispatchType;
    onTakeAction: (action: string, emailParams: any, ccEmails?: string[]) => PromiseDispatchType;
    onSearchInbox: (searchTerm: string) => void;
    uiState: {
        RESPONSE: Record<string, any>;
    };
    notesById: { [id: string]: any };
    featureFlags: Types.FeatureFlags;
} & ownPropTypes;

const mapStateToProps = (state: Types.RootState, _ownProps: ownPropTypes) => {
    return {
        uiState: state.uiState,
        notesById: {}, //state.note.notesById,
        featureFlags: state.global.featureFlags,
    };
};

const mapDispatchToProps = (dispatch: DispatchType, ownProps: ownPropTypes) => {
    let { currentSubmissionId, action } = ownProps.modalData; // TODO(JMM) - should have set this up differently ?

    const onGetTemplate = (params = {}) => {
        dispatch(getTemplate_server({ submissionId: currentSubmissionId, action, ...params }));
    };

    return {
        onSearchInbox: searchTerm => {
            dispatch(searchInbox_server(searchTerm));
        },
        onClearFormData: () => {
            dispatch(updeepUi(EMPTY_RESPONSE_OBJECT, 'RESPONSE'));
        },
        onSendResponse: params => {
            return dispatch(sendEmailResponse_server(currentSubmissionId, params));
        },
        onGetTemplate,
        onPutTemplate: (body, subject?) => {
            dispatch(putTemplate_server({ submissionId: currentSubmissionId, action, body, subject }));
        },
        onResetTemplate: () => {
            onGetTemplate({ reset: true });
        },
        onTakeAction: (action: string, emailParams: any, ccEmails?: string[]) => {
            return dispatch(takeAction(currentSubmissionId, ACTIONS_MAPPING[action], emailParams, ccEmails, true));
        },
        onGetReplyTemplates: () => {
            dispatch(getReplyTemplates_server({ submissionId: currentSubmissionId }));
        },
    };
};

class AddResponse extends React.Component<propTypes> {
    render() {
        return <AddResponseApp {...this.props} />;
    }
}

const connected: React.ComponentType<ownPropTypes> = connect(mapStateToProps, mapDispatchToProps)(AddResponse);
export default connected;
