import React, { useState } from 'react';
import classNames from 'classnames';

import { SubmissionType, UnderwritingPlatformType } from '../../ts-types/DataTypes';
import { CLEARANCE_STATUS } from '../../constants/Constants';
import { ComboBoxInput } from '@archinsurance-viki/property-jslib/src/components/inputs';

type buttonTypes = {
    currentSubmission: SubmissionType;
    name: string;
    pendingBorEnabled: boolean;
    onSaveFn: (fieldValueMap: any) => void;
    featureFlags: Record<string, boolean>;
    CONSTANTS: Record<string, any>;
};

const filterListByValues = (theList, theValues) => {
    return theList.filter(function (e) {
        return ['value'].every(function (a) {
            return theValues.includes(e[a]);
        });
    });
};

const UnderwritingPlatformToggle = (props: buttonTypes) => {
    const { underwriting_platform, supported_uw_platforms, status, last_user_action_failed, last_user_action_failed_action_type, inbox_id, is_renewal_linked } =
        props.currentSubmission;
    const retailEnabled = props.featureFlags.vmac_retail;
    const isRetailSubmission = inbox_id === props.CONSTANTS.INBOX_IDS.RETAIL;
    const isSMMSSubmission = inbox_id === props.CONSTANTS.INBOX_IDS.SMMS;

    const [platform, setPlatform] = useState(underwriting_platform);

    if (!underwriting_platform) {
        console.error('underwriting_platform is null, skipping');
        return null;
    }

    if (!supported_uw_platforms || supported_uw_platforms.length === 0) {
        console.error('underwriting_platform is null, skipping');
        return null;
    }

    const underwritingPlatformDisabled =
        status !== CLEARANCE_STATUS.UNPROCESSED ||
        (last_user_action_failed && ['C', 'B', 'D'].includes(last_user_action_failed_action_type)) ||
        isRetailSubmission ||
        isSMMSSubmission ||
        is_renewal_linked;
    function handleToggle() {
        if (underwritingPlatformDisabled) {
            return;
        }
        const newPlatform = platform === UnderwritingPlatformType.POLICYWRITER ? UnderwritingPlatformType.VIKI : UnderwritingPlatformType.POLICYWRITER;
        setPlatform(newPlatform);
        props.onSaveFn({ [props.name]: newPlatform });
    }

    function handleClick(target) {
        if (underwritingPlatformDisabled) {
            return;
        }
        setPlatform(target);
        props.onSaveFn({ [props.name]: target });
    }
    return (
        <React.Fragment>
            <If condition={retailEnabled}>
                <div className="header">
                    <div className="header-text">
                        Target Selected: <span className="blue-bright-txt">Policywriter</span>
                    </div>
                </div>
                <div className="info-rows">
                    <div className="input-label flex">Change Target</div>
                    <ComboBoxInput
                        object={props.currentSubmission}
                        disabled={underwritingPlatformDisabled}
                        label={null}
                        name="underwriting_platform"
                        onChange={handleToggle}
                        selectProps={{ choices: filterListByValues(props.CONSTANTS.UNDERWRITING_PLATFORM, supported_uw_platforms) }}
                    />
                </div>
            </If>
            <If condition={!retailEnabled}>
                <div
                    className={classNames('table-pref', {
                        active: platform === UnderwritingPlatformType.VIKI,
                        disabled: isRetailSubmission || isSMMSSubmission || is_renewal_linked,
                    })}
                    onClick={() => {
                        handleClick(UnderwritingPlatformType.VIKI);
                    }}
                >
                    Target Viki
                </div>
                <div
                    className={classNames('table-pref', {
                        active: platform === UnderwritingPlatformType.POLICYWRITER,
                        disabled: isRetailSubmission || isSMMSSubmission || is_renewal_linked,
                    })}
                    onClick={() => {
                        handleClick(UnderwritingPlatformType.POLICYWRITER);
                    }}
                >
                    Target Policywriter
                </div>
            </If>
        </React.Fragment>
    );
};

export default UnderwritingPlatformToggle;
