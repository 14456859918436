import * as React from 'react';
import { MouseEventHandler } from 'react';

import InfoBlock from '@archinsurance-viki/property-jslib/src/components/blocks/InfoBlock';
import { processColumnsForInfoBlocks } from '@archinsurance-viki/property-jslib/src/utils/tables/columns';
import { PagedTableDataType, TableGlossaryType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';
import ConfirmPromptButton from '@archinsurance-viki/property-jslib/src/components/popups/ConfirmPrompt';
import { AppContext } from '@archinsurance-viki/property-jslib/src/utils/context';
import { VMAC_BOTTOM_PANEL_EVENT } from '../../constants/AnalyticsConstants';
import { InboxType, SubmissionType } from '../../ts-types/DataTypes';
import { CLEARANCE_INFOBLOCKS } from '../../constants/InfoBlocks';
import { blockPropTypes, NoteType } from '../../ts-types/DataTypes';
import { Types } from '../../ts-types/icubed-types';
import ThrottledButton from '@archinsurance-viki/property-jslib/src/components/buttons/ThrottledButton';
import { TaskItem } from '@archinsurance-viki/property-jslib/src/components/tasks/VikiTasksPanel';
import Tooltip from '@archinsurance-viki/property-jslib/src/components/widgets/Tooltip';

type propTypes = {
    header?: string;
    readonly?: boolean;
    defs?: any;
    currentSubmission: SubmissionType;
    currentNotes: NoteType[];
    currentInbox: InboxType;
    onSaveFn?: (key: string, value: any) => void;
    onPopScheduleView?: MouseEventHandler<HTMLButtonElement>;
    onHandleAccountSearch?: MouseEventHandler<HTMLButtonElement>;
    onHandleConflictResolution?: MouseEventHandler<HTMLButtonElement>;
    onHandleUnlinkAccount?: MouseEventHandler<HTMLButtonElement>;
    onHandleCreateAccount?: MouseEventHandler<HTMLButtonElement>;
    onHandleBrokerMatching?: MouseEventHandler<HTMLButtonElement>;
    onHandleAddAccountBroker?: MouseEventHandler<HTMLButtonElement>;
    onHandleAddOrEditNote?: MouseEventHandler<HTMLButtonElement>;
    onHandleViewOfacResults?: MouseEventHandler<HTMLButtonElement>;
    onHandleRunBot: MouseEventHandler<HTMLButtonElement>;
    onHandleViewBuildingDistributions: MouseEventHandler<HTMLButtonElement>;
    onHandleProducerSearch?: MouseEventHandler<HTMLButtonElement>;
    onHandleOpenAlternativeNames?: MouseEventHandler<HTMLButtonElement>;
    CONSTANTS: Types.Constants;
    ENV: Types.Env;
    featureFlags: Types.FeatureFlags;
    tableData: PagedTableDataType;
    tableGlossary: TableGlossaryType;
    disableAllEdits: boolean;
    me: Record<string, any>;
    taskProgressInfo: TaskItem;
    onCreateOrUpdateArchAccount: () => void;
    onUnlinkArchAccount: (discardCurrentAccountData: boolean) => void;
};

const INFO_BLOCKS: blockPropTypes[] = [
    CLEARANCE_INFOBLOCKS.broker,
    CLEARANCE_INFOBLOCKS.retail_broker,
    CLEARANCE_INFOBLOCKS.arch_broker,
    CLEARANCE_INFOBLOCKS.insured,
    CLEARANCE_INFOBLOCKS.dates,
    CLEARANCE_INFOBLOCKS.sov_information,
    CLEARANCE_INFOBLOCKS.general_notes,
    CLEARANCE_INFOBLOCKS.submission_assignment,
    CLEARANCE_INFOBLOCKS.ofac,
    CLEARANCE_INFOBLOCKS.target_terms,
    CLEARANCE_INFOBLOCKS.conflicting_information,
];

type stateTypes = {
    insuredEditableOverride: boolean;
};

export default class BottomPanelApp extends React.Component<propTypes, stateTypes> {
    static contextType = AppContext;

    constructor(props: propTypes) {
        super(props);

        this.state = {
            insuredEditableOverride: false,
        };
    }

    switchOverride = () => {
        this.setState({ insuredEditableOverride: !this.state.insuredEditableOverride });
    };

    handleOnSave = (field: string, value: any) => {
        const { onSaveFn, currentSubmission, currentInbox } = this.props;
        this.context.analytics.track(VMAC_BOTTOM_PANEL_EVENT, {
            [field]: value,
            value_before: this.props.tableData.rowData[currentSubmission.id][field],
            inbox_id: currentInbox.id,
            submission_id: currentSubmission.id,
        });
        onSaveFn(field, value);
    };

    renderBlocks() {
        const {
            ENV,
            taskProgressInfo,
            disableAllEdits,
            tableGlossary,
            onSaveFn,
            CONSTANTS,
            currentSubmission,
            onHandleConflictResolution,
            onHandleAddOrEditNote,
            me,
            featureFlags,
        } = this.props;

        const readonly = currentSubmission.props && !!currentSubmission.props.readonly;
        const defs = processColumnsForInfoBlocks(CLEARANCE_INFOBLOCKS, tableGlossary, CONSTANTS);
        const blocks = [];

        for (let block of INFO_BLOCKS) {
            if (block.hideBlock && block.hideBlock(this.props)) {
                continue;
            }

            const isInsuredBlock = block.enableClientMatching && currentSubmission.arch_account_id && currentSubmission.arch_account_arch_id;

            const customHeaderButtons = [];
            let { headerButtons } = block;
            if (headerButtons) {
                headerButtons = typeof headerButtons === 'function' ? headerButtons(this.props) : headerButtons;
                for (let i = 0; i < headerButtons.length; i++) {
                    const isDisabled = headerButtons[i].disabledFn && headerButtons[i].disabledFn(currentSubmission);
                    if (this.props[headerButtons[i].callbackFn] && headerButtons[i].buttonName && !isDisabled) {
                        customHeaderButtons.push(
                            <ThrottledButton
                                throttleSeconds={headerButtons[i].throttleSeconds}
                                key={i}
                                label={headerButtons[i].buttonName}
                                onClick={this.props[headerButtons[i].callbackFn]}
                            />
                        );
                    }
                }
            }

            if (!disableAllEdits && isInsuredBlock && onHandleConflictResolution && currentSubmission.is_editable) {
                const isEarlyAccount = featureFlags.enable_early_account_alt_names_ff;
                if (!this.state.insuredEditableOverride) {
                    customHeaderButtons.unshift(
                        <ConfirmPromptButton
                            key="edit"
                            className="text-button default"
                            confirmPromptProps={{
                                onConfirmed: this.switchOverride,
                                confirmButtonTitle: 'Edit',
                                title: 'Edit this insured to update information',
                            }}
                        >
                            Edit
                        </ConfirmPromptButton>
                    );
                } else if (isEarlyAccount) {
                    // update
                    customHeaderButtons.unshift(
                        <ConfirmPromptButton
                            key="update"
                            className="text-button default"
                            confirmPromptProps={{
                                onConfirmed: () => {
                                    this.switchOverride();
                                    this.props.onCreateOrUpdateArchAccount();
                                },
                                confirmButtonTitle: 'Update',
                                title: 'Are you sure you want to update this account?',
                            }}
                        >
                            Update
                        </ConfirmPromptButton>
                    );
                    // cancel and create a new account
                    customHeaderButtons.unshift(
                        <ConfirmPromptButton
                            key="cancel"
                            className="text-button default"
                            confirmPromptProps={{
                                onConfirmed: () => {
                                    this.switchOverride();
                                    this.props.onUnlinkArchAccount(true);
                                },
                                confirmButtonTitle: 'Confirm',
                                title: 'Cancel reverts to empty account fields. Do you want to proceed?',
                            }}
                        >
                            Cancel
                        </ConfirmPromptButton>
                    );
                }
            }

            const isAssignmentBlock = block.header === 'Assignment';
            const isConflictingInfoBlock = block.header === 'Conflicting Info';
            const isBrokerBlock = block.header === 'Broker';
            const isDatesBlock = block.header === 'Dates';
            const smmsSubmissionFlag = currentSubmission.inbox_id === CONSTANTS.INBOX_IDS.SMMS;

            const disabled =
                (disableAllEdits && !isAssignmentBlock && !isConflictingInfoBlock && !taskProgressInfo?.in_progress) ||
                me.is_underwriter_assistant ||
                (smmsSubmissionFlag && !isAssignmentBlock && !isBrokerBlock) ||
                (currentSubmission.is_renewal_linked && !isAssignmentBlock && !isBrokerBlock && !isDatesBlock && !(block.header === 'Insured'));

            // disable adding notes if it's an SMMS submission
            if (smmsSubmissionFlag) {
                block.showAddRowIcon = false;
                block.alwaysShowAddRowIcon = false;
            }
            // add extraBeforeElement as an indicator for newly created arch account
            let extraBeforeElement = null;
            if (
                currentSubmission.arch_account &&
                currentSubmission.arch_account.arch_id === null &&
                currentSubmission.arch_account.status === null &&
                typeof currentSubmission.arch_clearance_api_submission_id !== undefined &&
                currentSubmission.status === 'U'
            ) {
                const content = 'Creating New Account';
                extraBeforeElement = (
                    <Tooltip content={content} attachment="right">
                        <div className="status-badge" style={{ minWidth: '100%', maxWidth: '100%', overflow: 'hidden' }}>
                            {content}
                        </div>
                    </Tooltip>
                );
            }

            blocks.push(
                <InfoBlock
                    ENV={ENV}
                    key={block.header}
                    rowFn={block.rowFn}
                    disabled={disabled}
                    readonly={!onSaveFn || readonly || disabled}
                    glossary={defs}
                    rowObjectGetter="currentSubmission"
                    parentProps={this.props}
                    customHeaderButtons={customHeaderButtons.length ? <div className="header-buttons">{customHeaderButtons}</div> : null}
                    {...block}
                    extraData={{ ...this.state, me: this.props.me, featureFlags }}
                    helperFunctions={{
                        handleSave: this.handleOnSave,
                        handleAddRow: block.alwaysShowAddRowIcon ? onHandleAddOrEditNote : null,
                        handleEditRow: block.alwaysShowAddRowIcon ? onHandleAddOrEditNote : null,
                        handleOpenAlternativeNames: this.props.onHandleOpenAlternativeNames,
                    }}
                    extraBeforeElement={rowKey => (rowKey === 'aanid' ? extraBeforeElement : null)}
                />
            );
        }
        return blocks;
    }

    render() {
        let { header, currentSubmission } = this.props;
        if (!currentSubmission) {
            return null;
        }

        return (
            <React.Fragment>
                <If condition={header}>
                    <div className="abs top-left flex handles">
                        <div className="panel-label handle">
                            <div className="text">{header}</div>
                        </div>
                    </div>
                </If>

                <div className="panel-area w-padding">
                    <div className="grid-layout gl-2">{this.renderBlocks()}</div>
                </div>
            </React.Fragment>
        );
    }
}
