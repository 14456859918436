export const BUSINESS_STRATEGIES = {
    SMALL_MEDIUM_ENTERPRISE: 'SME',
    SMALL: 'S',
    LARGE: 'L',
};

export const CLEARANCE_STATUS = {
    NEW: 'NEW',
    CLEARED: 'C',
    BLOCKED: 'B',
    UNPROCESSED: 'U',
    ARCHIVED: 'A',
    DECLINED: 'D',
    FORWARDED: 'F',
    REPLY: 'R',
    NONE: 'N',
    NEW_MESSAGE: 'NM',
    DISMISS: 'M',
    PENDING_BOR: 'P',
    RESOLVE_PENDING_BOR: 'S',
    RESTORE: 'Z',
};

export const EXTERNAL_SOURCES = {
    ARCH: 'ARCH',
    VIKI: 'VIKI',
};

export const SOV_TYPE = {
    AMRISC: 'AMRISC',
    NONAMRISC: 'NONAMRISC',
};

export const NOTE_TYPE = {
    USER_NOTE: 'UN',
    SUBMISSION_STATUS_CHANGE: 'SS',
    USER_ACTION: 'UA',
    INCOMING_MESSAGE: 'IM',
    MIGRATIONS: 'MI',
    IMPORT_UPDATE: 'IU',
};

export const DEFAULT_BODY = '';

export const EMPTY_RESPONSE_OBJECT = {
    body: DEFAULT_BODY,
    subject: '',
    arch_clearance_api_related_sid: null,
};

export const DEFAULT_SID = 'NA';

export const EMPTY_SUBMISSION_OBJECT = {
    archSid: DEFAULT_SID,
    vikiSid: DEFAULT_SID,
    vmacSid: DEFAULT_SID,
    id: null,
};

export const EMPTY_FWD_OBJECT = {
    submission_id: null,
    body: '',
    _errors: null,
    to_emails: null,
    arch_clearance_api_related_sid: null,
};

export const CANT_CLEAR_TOOLTIP = '';
export const CANT_BLOCK_TOOLTIP = '';

export const CLEAR_ACTION = 'submission_cleared';
export const BLOCK_ACTION = 'submission_blocked';
export const BLOCK_ACTION_UW = 'submission_blocked_uw';
export const REPLY_ACTION = 'submission_reply';
export const DECLINED_ACTION = 'submission_declined';
export const DECLINED_BOR_ACTION = 'submission_bor_declined';
export const CLEARED_BOR_ACTION = 'submission_bor_cleared';
export const BOR_RELEASED_PRIOR_TO_APPROVAL_CLEAR_BOR = 'bor_released_prior_to_approval_clear_bor';
export const BOR_RELEASED_PRIOR_TO_APPROVAL_DECLINE_INCUMBENT = 'bor_released_prior_to_approval_decline_incumbent';
export const RESOLVE_PENDING_BOR_ACTION = 'submission_resolve_pending_bor';
export const RELEASE_PENDING_APPROVAL = 'submission_release_pending_approval';
export const BOR_NOTIFICATION_ACTION = 'bor_notification_policy_service';
export const BOR_NOT_APPROVED = 'bor_not_approved';
export const BOR_APPROVED_TO_SUBMITTER = 'bor_approved_to_submitter';
export const BOR_APPROVED_TO_INCUMBENT = 'bor_approved_to_incumbent';

export const SUBMISSION_EMAIL = 'SUBMISSION_EMAIL';
export const DEFAULT_ZOOM_OUT = 8;

export const PASS = 'Pass';
export const FAIL = 'Fail';
export const UNKNOWN = 'Unknown';

export const MAXIMUM_BROKER_MATCHES = 5;
export const MAXIMUM_CLIENT_MATCHES = 14;

export const ACTIONS_MAPPING = {
    submission_cleared: 'C',
    submission_blocked: 'B',
    submission_reply: 'R',
    submission_declined: 'D',
    submission_resolve_pending_bor: 'S',
    submission_release_pending_approval: 'E',
    submission_unmark_pending_bor: 'U',
};

export const ARCH_CONFLICT_ERRORS = {
    SUBMISSION_CONFLICT: 'Encountered ArchLink Submission Conflict',
    ACCOUNT_CONFLICT: 'ARCH Account',
};

export const ARCH_DATA_CHECK_STATUSES = {
    CLIENT_VALIDATION_FAILED: 'CLIENT_VALIDATION_FAILED',
};

export const ARCH_API_MESSAGES = {
    ERROR: 'An error has occurred with the Arch API',
    IN_PROGRESS: 'The Arch API is syncing.',
    SUCCESS: 'The Arch API has successfully synced!',
    NOT_CALLED: 'The Arch API has not been called.',
};

export const ICONS = {
    ERROR: { icon: 'report', color: 'red' },
    WARN: { icon: 'warning', color: 'orange' },
    SUCCESS: { icon: 'check_circle', color: 'green' },
    NOT_CALLED: { icon: 'warning', color: 'orange' },
};

export const ARCH_STATUSES = {
    SUCCESS: 'SUCCESS',
    IN_PROGRESS: 'IN_PROGRESS',
    PENDING_PROCESSING: 'PENDING_PROCESSING',
    PROCESSING: 'PROCESSING',
    WARN: 'WARN',
    ERROR: 'ERROR',
};

export const SUBDIVISION_CODES = {
    SMMS: '6312',
    VENTUS_HO: '5011',
    ES_PROPERTY: '5010',
    RETAIL_PROPERTY: '5020',
};

export const ARCH_CLEARANCE_API_SUBMISSION_STATUSES: Record<string, any> = {
    SUBMISSON_CONFLICT: 'SUBMISSION_CONFLICT',
    ACCOUNT_CONFLICT: 'ACCOUNT_CONFLICT',
};

export const POLICY_SERVICES_EMAILS = {
    VENTUS: 'Policyservice@ventusrisk.com',
    ARCH: 'PropertyUWSvc@archinsurance.com',
};

export const INBOX_IDS = {
    RETAIL: 4,
    ARCH_E_AND_S: 2,
    VENTUS: 1,
    SMMS: 3,
};

export const PENDING_BOR_STATUSES = {
    PENDING_APPROVAL: 'N',
};

export const BOTTOM_PANEL_DEFAULT_INDEX = 3;

export const SITE_ID = {
    VENTUS: 1,
    RAE: 5,
};
